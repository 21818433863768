import React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";

const axios = require("axios").default;

function FormulariosPage(props) {
    return (
        <>
            <SEO title="Formulários" />
            {!props["*"] && (
                <section className="py-8 px-12 lg:py-16 lg:px-6">
                    <div className="container mx-auto  text-azul_base ">
                        <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl lg:mb-12">Formulários</h1>
                        <div className="flex flex-col lg:flex-row lg:justify-start">
                            <p className="text-base text-preto leading-none lg:text-vermelho lg:w-1/3 lg:text-lg lg:leading-tight">
                                Procurando a comodidade, eficácia no atendimento e pensando no meio ambiente, o 21º
                                Cartório da Saúde oferece o preenchimento de formulários online para a solicitação de
                                diversos serviços. Veja os formulários ao lado e encontre aquele que deseja. Em seguida,
                                basta clicar no Link, preencher com todas as informações que será enviado para nós!
                            </p>
                            <div className="text-base mt-4 lg:text-lg lg:w-2/3 lg:mt-0 lg:ml-32 lg:flex lg:flex-wrap ">
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Certidão de Casamento</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/certidao-de-casamento">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Habilitação de Casamento</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/habilitacao-de-casamento">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">
                                        Procuração Pública <br /> para Empresa
                                    </p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/procuracao-para-empresa">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">
                                        Procuração Pública <br /> Pessoa Física
                                    </p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/procuracao-para-pessoa-fisica">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">
                                        Cadastro de Empresa <br /> Mensalista
                                    </p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/cadastro-de-empresa-mensalista">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Certidão de Nascimento</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/certidao-de-nascimento">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Registro de Nascimento</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/registro-de-nascimento">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Certidão de Óbito</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/certidao-de-obito">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                                <div className="lg:mr-20 lg:mb-4">
                                    <p className="text-azul_base font-bold">Registro de Óbito</p>
                                    <button className="mb-5 mt-2">
                                        <Link
                                            className="text-white bg-azul_claro rounded px-4 py-2"
                                            to="/formularios/registro-de-obito">
                                            Preencher Formulário
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {props["*"] && (
                <div className="container mx-auto pt-4 pb-24 px-5 lg:px-0">
                    <div className="">
                        <button
                            onClick={() => window.history.back()}
                            className="text-azul_claro hover:underline cursor-pointer font-bold text-lg w-full text-left lg:w-auto">
                            &lt; voltar
                        </button>
                    </div>
                    <div className="max-w-screen-md mx-auto">
                        <Formik
                            initialValues={{
                                razaoSocial: "",
                                cnpj: "",
                                endereco: "",
                                bairro: "",
                                municipio: "",
                                estado: "",
                                nomeCompletoSocioRepresentante: "",
                                nacionalidade: "",
                                estadoCivil: "",
                                profissao: "",
                                RG: "",
                                CPF: "",
                                email: "",
                                endereco2: "",
                                municipio2: "",
                                file: "",
                                accept: false,
                            }}
                            onSubmit={(values, actions) => {
                                var formData = new FormData();
                                
                                formData.append('tipo', props["*"]);
                                formData.append('razaoSocial', values.razaoSocial);
                                formData.append('cnpj', values.cnpj);
                                formData.append('endereco', values.endereco);
                                formData.append('bairro', values.bairro);
                                formData.append('municipio', values.municipio);
                                formData.append('estado', values.estado);
                                formData.append('nomeCompletoSocioRepresentante', values.nomeCompletoSocioRepresentante);
                                formData.append('nacionalidade', values.nacionalidade);
                                formData.append('estadoCivil', values.estadoCivil);
                                formData.append('profissao', values.profissao);
                                formData.append('RG', values.RG);
                                formData.append('CPF', values.CPF);
                                formData.append('email', values.email);
                                formData.append('endereco2', values.endereco2);
                                formData.append('municipio2', values.municipio2);
                                formData.append('file', values.file);

                                return axios
                                    .post("/sendservice.php", formData)
                                    .then(res => {
                                        if (res.data.success) {
                                            actions.resetForm();
                                            document.getElementById("success").innerText =
                                            "Formulário enviado com sucesso";
                                            setTimeout(() => {
                                                document.getElementById("success").innerText = "";
                                            }, 5000);
                                        } else {
                                            actions.setFieldError(
                                                "accept",
                                                res.data.message
                                            );
                                        }
                                    })
                                    .catch(err => {
                                        actions.setFieldError(
                                            "accept",
                                            "Ocorreu um erro ao enviar o formulário, por favor tente novamente"
                                        );
                                    })
                                ;
                            }}
                            validationSchema={Yup.object().shape({
                                razaoSocial: Yup.string().required('Campo obrigatório'),
                                cnpj: Yup.string().required('Campo obrigatório'),
                                endereco: Yup.string().required('Campo obrigatório'),
                                bairro: Yup.string().required('Campo obrigatório'),
                                municipio: Yup.string().required('Campo obrigatório'),
                                estado: Yup.string().required('Campo obrigatório'),
                                nomeCompletoSocioRepresentante: Yup.string().required('Campo obrigatório'),
                                nacionalidade: Yup.string().required('Campo obrigatório'),
                                estadoCivil: Yup.string().required('Campo obrigatório'),
                                profissao: Yup.string().required('Campo obrigatório'),
                                RG: Yup.string().required('Campo obrigatório'),
                                CPF: Yup.string().required('Campo obrigatório'),
                                endereco2: Yup.string().required('Campo obrigatório'),
                                municipio2: Yup.string().required('Campo obrigatório'),
                                email: Yup.string().email("este não é um e-mail válido").required("Campo obrigatório"),
                                accept: Yup.boolean().oneOf([true], 'Leia e Aceite as Políticas de Privacidade antes de enviar'),
                            })}>
                            {({
                                values,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => {
                                return (
                                    <form className="flex flex-col" onSubmit={handleSubmit}>
                                        <h1 className="text-2xl lg:text-4xl mb-2 text-azul_claro font-bold">
                                            Formulário de {props["*"].replace(/[-]/g, " ")}
                                        </h1>
                                        <p className="text-cinza_default mb-6 text-lg">Preencha os dados abaixo.</p>
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="razaoSocial">
                                            Razão Social <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field
                                            id="razaoSocial"
                                            type="text"
                                            value={values.razaoSocial}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            className="rounded-lg p-2 bg-cinza_claro mb-4 w-full"
                                        />
                                        <ErrorMessage name="razaoSocial" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="cnpj">
                                            CPNJ <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.cnpj}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="cnpj"
                                            id="cnpj"
                                        />
                                        <ErrorMessage name="cnpj" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="endereco">
                                            Endereço <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.endereco}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="endereco"
                                            id="endereco"
                                        />
                                        <ErrorMessage name="endereco" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="bairro">
                                            Bairro <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.bairro}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="bairro"
                                            id="bairro"
                                        />
                                        <ErrorMessage name="bairro" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="municipio">
                                            Município <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.municipio}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="municipio"
                                            id="municipio"
                                        />
                                        <ErrorMessage name="municipio" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="estado">
                                            Estado <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.estado}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="estado"
                                            id="estado"
                                        />
                                        <ErrorMessage name="estado" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <p className="text-xl mb-4 text-azul_claro font-bold max-w-lg">
                                            Sócio e/ou Representante, Administrador/Diretor da empresa com poderes de representação da
                                            empresa
                                        </p>
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="nomeCompletoSocioRepresentante">
                                            Nome Completo - Sócio ou Representante{" "}
                                            <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field
                                            className="rounded-lg p-2 bg-cinza_claro mb-4 w-full"
                                            type="text"
                                            value={values.nomeCompletoSocioRepresentante}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="nomeCompletoSocioRepresentante"    
                                            id="nomeCompletoSocioRepresentante"
                                        />
                                        <ErrorMessage name="nomeCompletoSocioRepresentante" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="nacionalidade">
                                            Nacionalidade <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.nacionalidade}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="nacionalidade"
                                            id="nacionalidade"
                                        />
                                        <ErrorMessage name="nacionalidade" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="estadoCivil">
                                            Estado Civil <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field as="select" className="p-2 bg-cinza_claro mb-4" name="estadoCivil"
                                            value={values.estadoCivil}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="estadoCivil"
                                            id="estadoCivil"
                                        >
                                            <option value="">Selecione uma opção</option>
                                            <option value="solteiro">Solteiro (nunca foi casado civilmente)</option>
                                            <option value="casado">Casado</option>
                                            <option value="divorciado">Divorciado</option>
                                            <option value="viúvo">Viúvo</option>
                                        </Field>
                                        <ErrorMessage name="estadoCivil" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="profissao">
                                            Profissão <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.profissao}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="profissao"
                                            id="profissao"
                                        />
                                        <ErrorMessage name="profissao" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="RG">
                                            RG <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.RG}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="RG"
                                            id="RG"
                                        />
                                        <ErrorMessage name="RG" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="CPF">
                                            CPF <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.CPF}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="CPF"
                                            id="CPF"
                                        />
                                        <ErrorMessage name="CPF" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="email">
                                            E-mail <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.email}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="email"
                                            id="email"
                                        />
                                        <ErrorMessage name="email" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="endereco2">
                                            Endereço (Número, Complemento, CEP) <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.endereco2}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="endereco2"
                                            id="endereco2"
                                        />
                                        <ErrorMessage name="endereco2" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="block mb-2 text-azul_base text-lg" htmlFor="municipio2">
                                            Município <i className="text-vermelho not-italic font-bold">*</i>
                                        </label>
                                        <Field className="rounded-lg p-2 bg-cinza_claro mb-4 w-full" type="text"
                                            value={values.municipio2}
                                            onchange={handleChange}
                                            onBlur={handleBlur}
                                            name="municipio2"
                                            id="municipio2"
                                        />
                                        <ErrorMessage name="municipio2" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <p className="text-xl mb-4 text-azul_claro font-bold max-w-lg">
                                            Anexar arquivo (PDF, PNG ou docX - máx 25 Mb)
                                        </p>
                                        <input
                                            id="file"
                                            type="file"
                                            onChange={(e) => {setFieldValue("file", e.target.files[0])}}
                                            onBlur={handleBlur}
                                            className="p-3 bg-cinza_claro rounded mb-4"
                                        />
                                        <ErrorMessage name="file" render={(msg) => (
                                            <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                        )} />
                                        <label className="text-preto">
                                            <input
                                                id="accept"
                                                type="checkbox"
                                                checked={values.accept}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="color-black"
                                            />
                                            &nbsp;&nbsp;Estou de acordo com o <a href="/terms" className="font-bold" target="_blank">Termo de Uso de Dados</a>
                                        </label>
                                        <ErrorMessage name="accept" render={(msg) => (
                                            <div className="text-vermelho mt- mb-4">{msg}</div>
                                        )} />
                                        <div className="text-green-700" id="success"></div>
                                        <div className="flex justify-end mb-12">
                                            <button
                                                className={`px-6 py-2 text-white cursor-pointer ${
                                                    isSubmitting ? "bg-gray-800" : "bg-azul_claro"
                                                } rounded`}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Enviar formulário
                                            </button>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                        <div>
                            <p className="text-lg text-azul_claro">Dúvidas</p>
                            <p className="text-lg text-cinza_default">Email: mensalistas@cartoriosaude.com.br </p>
                            <p className="text-lg text-cinza_default">Telefone (11)55859945 Ramal 25/31</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FormulariosPage;
